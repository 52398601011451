<template>
  <div>
    <table-list
      class="tableList"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="list"
      :title="tableTitle"
      :options="{ selection: true, fixScrollTable: true }"
      :columns="columns(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '学习统计'
const btns = _this => [
  {
    label: '导出学习记录',
    type: 'primary',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '培训班',
    prop: 'privateTrainId',
    defaultValue: _this.privateTrainId,
    type: 'select',
    children: _this.classNameList.map(item => ({
      value: item.certPrivateTrainId,
      label: item.certPrivateTrainName,
    })),
  },
  {
    label: '姓名',
    prop: 'userId',
    type: 'select',
    children: _this.selectTypeList.map(item => ({
      value: item.userId,
      label: item.userName,
    })),
  },
  {
    label: '手机号',
    prop: 'mobile',
  },
]
// 表格列配置
const columns = _this => [
  {
    prop: 'userName',
    label: '姓名',
    minWidth: '70px',
    render: (h, { row }) => {
      return [
        h(
          'el-button',
          {
            props: { type: 'text' },
            on: {
              click: () => {
                _this.onShowView(row)
              },
            },
          },
          row.userName,
        ),
      ]
    },
  },
  {
    prop: 'certPrivateTrainName',
    label: '所在培训班',
    minWidth: '200px',
    twoLines: true,
  },
  {
    prop: 'mobile',
    label: '手机号',
    minWidth: '110px',
  },
  {
    prop: 'cardNo',
    label: '身份证号',
    minWidth: '170px',
    twoLines: true,
  },
  {
    prop: 'studyTime',
    label: '学习开始到完成时间',
    minWidth: '200px',
    render: (h, { row }) => {
      return [
        <div style='white-space: pre-wrap;'>
          {'开始：' +
            (row.beginTime ? row.beginTime : '--') +
            `\n` +
            '结束：' +
            (row.endTime && row.isStudyEnd == true ? row.endTime : '--')}
        </div>,
      ]
    },
  },
  {
    prop: 'studyDuration',
    label: '已学时长(分钟)',
    minWidth: '115px',
  },
  {
    prop: 'classDuration',
    label: '应学时长(分钟)',
    minWidth: '115px',
  },
  {
    prop: 'studySectionCount',
    label: '已学章节数',
    minWidth: '90px',
  },
  {
    prop: 'sectionCount',
    label: '应学章节数',
    minWidth: '90px',
  },
  {
    prop: 'progress',
    label: '学习进度',
    minWidth: '100px',
    sortable: 'sortable',
  },
]

import TableList from '@/components/TableList'
import { classCertQuery, getClassStudy, downloadGetClassStudy } from '@/api/certCenter'
import to from 'await-to'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      btns,
      searchForm,
      columns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      select: [],
      list: [],
      selectTypeList: [],
      classNameList: [],
      privateTrainId: '',
    }
  },
  created() {
    let privateTrainId = parseInt(this.$route.query.privateTrainId)
    if (privateTrainId > 0) {
      this.privateTrainId = privateTrainId
      this.queryParams = {
        privateTrainId: privateTrainId,
      }
    }
    this.getClassList()
    this.selectTypeData()
    this.onGetClassStudy()
  },
  methods: {
    //数组[字典]去重
    dataHeavy(data, key) {
      let obj = {}
      let peon = data.reduce((cur, next) => {
        obj[next[key]] ? '' : (obj[next[key]] = true && cur.push(next))
        return cur
      }, [])
      return peon
    },
    async onGetClassStudy() {
      this.loading = true
      const { current, size } = this.pager
      const [res, err] = await to(getClassStudy({ current, size, ...this.queryParams }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.list = res.data.list.map(item => ({
        ...item,
        cardNo: item.cardNo ? item.cardNo : '--',
        // studyTime:
        //   '开始：' +
        //   (item.beginTime ? item.beginTime : '--') +
        //   '结束：' +
        //   (item.endTime && item.isStudyEnd == true ? item.endTime : '--'),
      }))
      console.log(res)
      this.pager.total = res.data.total
    },
    async selectTypeData() {
      const { current } = this.pager
      const [res, err] = await to(getClassStudy({ current, size: 100000 }))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.selectTypeList = this.dataHeavy(res.data.list, 'userId')
      console.log(this.selectTypeList)
    },
    async getClassList() {
      const { current } = this.pager
      const [res, err] = await to(
        classCertQuery({
          current,
          size: 100000,
          publishStatus: 1,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      this.classNameList = res.data.list
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.onGetClassStudy()
    },
    handleSelectionChange(val) {
      this.select = val
    },
    async handleExport() {
      let certIdList = []
      this.select.map(v => certIdList.push(v.appointId))
      const { current, size } = this.pager
      const [res, err] = await to(
        downloadGetClassStudy({ current, size, ...this.queryParams, appointIds: certIdList }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '学习记录.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.onGetClassStudy()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.onGetClassStudy()
    },
    onShowView(row) {
      this.$router.push({
        path: '/certCenter/studydetail',
        query: {
          privateTrainId: row.certPrivateTrainId,
          certRelationTrainId: row.certRelationTrainId,
          userId: row.userId,
        },
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
